import React from 'react';
import PropTypes from 'prop-types';
import nutanixLogo from 'assets/nutanix_logo_iam.png';
import {
  FlexLayout,
  FlexItem,
  Title,
  Paragraph
} from 'prism-reactjs';

import './LoginLayout.less';

export default class LoginLayout extends React.Component {

  static propTypes = {
    leftTitle: PropTypes.string,
    customLoginEle: PropTypes.object,
    form: PropTypes.node,
    id: PropTypes.string
  }

  getContainerStyle(color_in, color_out) {
    return color_in && color_out ? {
      opacity: 0.3,
      background: `linear-gradient(45deg, ${color_in}, ${color_out})`
    } : {};
  }

  render() {
    const { form, leftTitle, id, customLoginEle } = this.props;
    const { color_in, color_out, product_title, title } = customLoginEle || {};
    const containerStyle = this.getContainerStyle(color_in, color_out);
    let prismTitleClass = '';

    if (product_title && (product_title.trim().toUpperCase() === 'PRISM')) {
      prismTitleClass = 'prism-title-space';
    }

    return (
      <FlexLayout
        className="content-wrapper"
        justifyContent="center"
        alignItems="center"
      >
        <FlexLayout className="login-layout">
          <FlexLayout
            className="left-container"
            id="left-container"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Title
              className={ `left-tile-text iam-center-text ${prismTitleClass}` }
              id="left-title-text"
              size="h1"
            >{ product_title || leftTitle }</Title>
            <Paragraph className="dark-bg">{ title }</Paragraph>
            <FlexItem className="overlay-bg"
              style={ containerStyle } />
          </FlexLayout>
          <FlexItem className="right-container" flexGrow="1" id="loginBackground">
            <FlexLayout alignItems="center" flexDirection="column"
              justifyContent="space-between" id={ id }
              className="login-full-height">
              <FlexLayout justifyContent="center">
                <img className="nutanix-logo" src={ nutanixLogo } alt="Nutanix color logo" />
              </FlexLayout>
              <FlexLayout className="login-right-inner" alignItems="center"
                justifyContent="center" flexGrow="1">
                { form }
              </FlexLayout>
            </FlexLayout>
          </FlexItem>
        </FlexLayout>
      </FlexLayout>
    );
  }

}
